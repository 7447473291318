import React, { Suspense, useEffect, useState, lazy } from "react";

// Libraries
import { Routes, Route, useLocation } from "react-router-dom";
import retina from "retinajs";
import { AnimatePresence } from "framer-motion";

// Context
import GlobalContext from "./Context/Context";

// Components
import ScrollToTopButton from "./Components/ScrollToTop"
// Home
const Home = lazy(() => import("./Pages/Home"))

// About Pages
const AboutUs = lazy(() => import("./Pages/AboutUs"))

// Contact Pages
const ContactUs = lazy(() => import("./Pages/ContactUs"))

// Additional Pages
const Management = lazy(() => import("./Pages/Management"))

const Infrastructure = lazy(() => import("./Pages/Infrastructure"))
const News = lazy(() => import("./Pages/News"))
const NewsDetails = lazy(() => import("./Pages/NewsDetails"))
const Careers = lazy(() => import("./Pages/Careers"))
const Tenders = lazy(() => import("./Pages/Tenders"))
const Events = lazy(() => import("./Pages/Events"))
const PhotoGallery = lazy(() => import("./Pages/PhotoGallery"))
const VideoGallery = lazy(() => import("./Pages/VideoGallery"))
const MediaInKabco = lazy(() => import("./Pages/MediaInKabco"))
const MediaInKabcoDetails = lazy(() => import("./Pages/MediaDetails"))

const NotFoundPage = lazy(() => import("./Pages/404"))
const MaintenancePage = lazy(() => import("./Pages/AdditionalPages/MaintenancePage"))
const ComingSoonPage = lazy(() => import("./Pages/AdditionalPages/ComingSoonPage"))

function App() {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customModal, setCustomModal] = useState({
    el: null,
    isOpen: false
  })
  const location = useLocation();

  // RetinaJS
  useEffect(() => {
    window.addEventListener('load', retina(document.querySelectorAll('img')));
  }, [])

  useEffect(() => {
    setTimeout(() => {
      import("./Functions/Utilities").then(module => {
        module.SetHeaderMenuPos()
        module.setDocumentFullHeight()
      })
    }, 1000);
  }, [location])

  useEffect(() => {
    if (isModalOpen === true) {
      document.querySelector("body").classList.add("overflow-hidden");
    } else {
      document.querySelector("body").classList.remove("overflow-hidden");
    }
  }, [isModalOpen]);

  // Get the current location and set the window to top
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    setFooterHeight(0);
    setCustomModal({
      ...customModal,
      el: null,
      isOpen: false
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <GlobalContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
        footerHeight,
        setFooterHeight,
        isModalOpen,
        setIsModalOpen,
        customModal,
        setCustomModal,
      }}
    >
      <div className="App" style={{ "--header-height": `${headerHeight}px` }}>
        {
          <main style={{ marginTop: headerHeight, marginBottom: footerHeight }}>
            <ScrollToTopButton />
            <AnimatePresence exitBeforeEnter>
              <Suspense fallback={<></>}>
                <Routes>
                  <Route path="/" element={<Home style={{ "--base-color": "#055890" }} />} />

                  {/* Home Corporate*/}
                  <Route path="/home" element={<Home style={{ "--base-color": "#055890" }} />} />

                  {/*About Pages */}
                  <Route path="/about-us" element={<AboutUs style={{ "--base-color": "#055890" }} />} />

                  {/* Contact Pages */}
                  <Route path="/contact" element={<ContactUs style={{ "--base-color": "#055890" }} />} />

                  {/* Additional Pages */}
                  <Route path="/management" element={<Management style={{ "--base-color": "#055890" }} />} />
                  <Route path="/infrastructure" element={<Infrastructure style={{ "--base-color": "#055890" }} />} />
                  <Route path="/careers" element={<Careers style={{ "--base-color": "#055890" }} />} />
                  <Route path="/news" element={<News style={{ "--base-color": "#055890" }} />} />
                  <Route path="/photo-gallery" element={<PhotoGallery style={{ "--base-color": "#055890" }} />} />
                  <Route path="/video-gallery" element={<VideoGallery style={{ "--base-color": "#055890" }} />} />
                  <Route path="/kabco-in-media" element={<MediaInKabco style={{ "--base-color": "#055890" }} />} />
                  <Route path="/media/:mediaId" element={<MediaInKabcoDetails style={{ "--base-color": "#055890" }} />} />
                  <Route path="/news/:newsID" element={<NewsDetails style={{ "--base-color": "#055890" }} />} />
                  <Route path="/tenders" element={<Tenders style={{ "--base-color": "#055890" }} />} />
                  <Route path="/events" element={<Events style={{ "--base-color": "#055890" }} />} />
                  <Route path="/media" element={<ComingSoonPage style={{ "--base-color": "#055890" }} />} />

                  <Route path="/page/error-404" element={<NotFoundPage style={{ "--base-color": "#055890" }} />} />
                  <Route path="/page/maintenance" element={<MaintenancePage />} />
                  <Route path="/page/coming-soon" element={<ComingSoonPage />} />
                  <Route path="*" element={<NotFoundPage />} />

                </Routes>
              </Suspense>
            </AnimatePresence>
          </main>
        }
      </div>
    </GlobalContext.Provider>
  )
}

export default App;